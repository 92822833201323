import React from "react";
import styled from "styled-components";
import { FAQS } from "../constants";
import breakpoints from "./breakpoints";

const FAQsCell = styled.div`
  opacity: 0.85;
  background: #f6f6f6;
  margin-top: 24px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 30px;
  max-width: 978px;
`;

const FAQDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  opacity: 1;
  color: #6a6a6a;
`;

const FAQsTitle = styled.div`
  margin-bottom: 14px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #6a6a6a;
`;

const FAQsGrid = styled.div`
  row-gap: 35px;
  column-gap: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${breakpoints.down("small")`
  grid-template-columns: 1fr;
`}

  grid-template-rows: 1fr min-content;
`;
const FAQs = ({ }) => {
  return (
    <FAQsCell>
      <FAQsTitle style={{ marginBottom: 35 }}>
        Frequently asked questions
      </FAQsTitle>
      <FAQsGrid>
        {FAQS.map((faq) => (
          <div
            style={{ display: "flex", flexDirection: "column" }}
            key={faq.question}
          >
            <FAQsTitle>{faq.question}</FAQsTitle>
            <FAQDescription>{faq.answer}</FAQDescription>
          </div>
        ))}
      </FAQsGrid>
    </FAQsCell>
  );
};
export default FAQs;
