export async function notifySlack(message: string) {
  if (process.env.NODE_ENV !== "development" || true) {
    await window.fetch(
      "https://hooks.slack.com/services/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",
      {
        method: "POST",
        body: JSON.stringify({ text: message }),
      }
    );
  } else {
    console.log("NOT SENDING SLACK MESSAGE SINCE IN DEV MODE");
  }
}
