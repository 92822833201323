/**
 * dom todos
 * - add 2 more faqs
 * - add languages. Add react, machine language
 *  - use // window.gtag("event", "click", { ...data })
 */

import * as React from "react";
import { Link } from "gatsby";
import Button from "../components/Button";

import { useQueryParam, NumberParam, StringParam } from "use-query-params";

import ReactModal from "react-modal";

import _ from "lodash";
import { useEffect, useRef } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useState } from "react";
import { Helmet } from "react-helmet";
import CloseButton from "../components/CloseButton";
import Prism from "prismjs";
import Select from "react-select";
// import "react-dropdown/style.css";

import styled from "styled-components";
import { getCodeForLanguage } from "../codeSamples/codeSamples";
import FAQs from "../components/faqs";
import AttentionGrabber from "../components/AttentionGrabber";
import ResultsModal from "../components/ResultsModal";
import { COLORS, LANGUAGES } from "../constants";
import Layout from "../components/layout";
import breakpoints from "../components/breakpoints";
import { notifySlack } from "../utils";
import FilloutBranding from "../components/FilloutBranding";

const MainContent = styled.div`
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 50px;
`;

const TwitterLink = styled.a`
  border: 2px solid #f3f3f3;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 6px 14px;
  background: white;
`;

const TwitterLinkContainer = styled.div`
  justify-content: flex-end;
  position: fixed;
  z-index: 1000;
  bottom: 12px;
  right: 12px;

  ${breakpoints.down("small")`
  justify-content: center;
margin-bottom: 10px;
  `}
`;

const MainInputContainer = styled.div`
  background: white;

  // // Make new lines not possible in contenteditable spans
  // [contenteditable="true"].single-line {
  //   white-space: nowrap;
  //   // overflow: hidden;
  // }
  // [contenteditable="true"].single-line br {
  //   display: none;
  // }
  // [contenteditable="true"].single-line * {
  //   display: inline;
  //   white-space: nowrap;
  // }

  // Important - make the whole div scroll horizontally
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: hidden;

  padding: 20px;
  margin-top: 50px;
  margin-bottom: 30px;

  border: 3px solid #484848;
  border-radius: 10px;
  // width: 1000px;
  max-width: 1000px;
  width: 85%;
  margin-left: 10px;
  margin-right: 10px;

  input:focus,
  h1:focus,
  span:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
`;

const QuestionsModal = ({
  setShowQuestionsModal,
}: {
  setShowQuestionsModal: (v: boolean) => void;
}) => {
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [sent, setSent] = useState(false);
  const isDisabled = !text || !email;
  return (
    <>
      <CloseButton close={() => setShowQuestionsModal(false)}></CloseButton>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <strong style={{ marginBottom: 5 }}>Email</strong>
        <input
          autoFocus
          type="email"
          name="name"
          placeholder="john@smith.com"
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <strong style={{ marginBottom: 5 }}>Questions/Feedback</strong>
        <textarea
          rows={5}
          placeholder="Type here..."
          onChange={(e) => setText(e.target.value)}
        ></textarea>
        <br />
        {sent ? (
          <div>Message sent ✅</div>
        ) : (
          <Button
            disabled={!text || !email}
            style={{
              ...(isDisabled && { background: "gray", opacity: 0.7 }),
            }}
            onClick={() => {
              notifySlack(
                ["*New Message from:* ", email, "\n *Message:* ", text].join("")
              );
              setSent(true);
            }}
          >
            Send
          </Button>
        )}
      </div>
    </>
  );
};
const CodeTextDiv = styled.div`
  font-size: 20pt;
  border: 0 solid #d4d4d7;

  font-family: "Ubuntu Mono", monospace;
`;

const MainInput = styled.span`
  display: flex;
  align-items: center;
  height: 100px;
  font-size: 20pt;
  border: 0 solid #d4d4d7;
  margin-right: ${(props: any) => (props.noMargin ? "0px" : "16px")};
  font-family: "Ubuntu Mono", monospace;

  ${(props: any) =>
    props.correct &&
    `
  color: green;
  `}
  ${(props: any) =>
    props.incorrect &&
    `
  color: ${COLORS.red};
  text-decoration: line-through;
  `}

  ${(props: any) =>
    props.faint &&
    `
  color: gray !important
  `}
`;

// eslint-disable-next-line react/display-name
const Input: any = React.forwardRef((props, ref: any) => (
  <MainInput
    className="single-line"
    ref={ref}
    {...props}
    spellCheck={false}
    autoCorrect="off"
  >
    {props.children}
  </MainInput>
));

/**
 *
 * @param goalWord
 * @param attempt what has been typed so far
 * @returns remainingGoalWord is goalWord - attempt (i.e. num chars remaining, even if some are wrong).
 * match is what matches
 */
const parseAttempt = (goalWord: string, attempt: string) => {
  let i = attempt.length;
  let match = "";

  while (i--) {
    const subS = attempt.substring(0, i + 1);
    if (goalWord.startsWith(subS)) {
      match = subS;

      break;
    }
  }

  return {
    remainingGoalWord: goalWord.substring(match.length, goalWord.length),
    match,
  };
};

/**
 * Takes a goal word. Shows an input for what has been typed so far
 * and shows a div to the right of it with what remains to be typed
 */
const AttemptInput = ({
  goalWord,
  onFinish,
  currentInput,
  currentWordIndex,
  currentLine,
  isLastWordInLine,
  setHasStarted,
  hasStarted,
}: {
  goalWord: string;
  currentWordIndex: number;
  currentLine: number;
  isLastWordInLine: boolean;
  currentInput: any;
  onFinish: (correct: boolean, attempt: string) => void;
  setHasStarted: (hasStarted: boolean) => void;
  hasStarted: boolean;
}) => {
  const [attempt, setAttempt] = useState("");

  const { remainingGoalWord, match } = parseAttempt(goalWord, attempt);

  useEffect(() => {
    // reset when currentWordIndex changes
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    currentInput!.current!.textContent = "";

    setAttempt("");
  }, [currentWordIndex, currentInput, currentLine]);

  useEffect(() => {
    // Restart after you press "try again"
    if (!hasStarted) {
      currentInput!.current!.textContent = "";
      setAttempt("");
    }
  }, [hasStarted]);

  useEffect(() => {
    function handleInput(e: any) {
      if (!hasStarted) {
        setHasStarted(true);
      }
      const newString = currentInput?.current?.textContent as string;

      if (
        e.inputType === "insertParagraph" ||
        (e.inputType === "insertText" && e.data === " ")
      ) {
        onFinish(attempt === goalWord, attempt);
        e.preventDefault();
        return;
      }

      setAttempt(newString);
    }

    if (attempt === "") {
      currentInput?.current?.focus();
    }
    currentInput?.current?.addEventListener("input", handleInput);
    return () => {
      currentInput?.current?.removeEventListener("input", handleInput);
    };
  }, [attempt, goalWord, onFinish, currentInput, hasStarted, setHasStarted]);

  const isCorrect = attempt === match;
  return (
    <>
      <Input
        correct={isCorrect}
        incorrect={!isCorrect}
        focus
        contentEditable
        ref={currentInput}
        noMargin
        style={{
          height: 30,
          paddingLeft: 1,
        }}
      ></Input>
      {/* No margin for last word to avoid moving things about (since normal text doesn't have a space after) */}
      <Input noMargin={isLastWordInLine}>{remainingGoalWord}</Input>
    </>
  );
};

type AlreadyTypedWords = { word: string; correct: boolean }[];

const AlreadyTypedWords = (props: { alreadyTypedWords: AlreadyTypedWords }) => {
  return (
    <>
      {props.alreadyTypedWords.map((alreadyTypedWord, i) => (
        <Input
          key={i}
          correct={alreadyTypedWord.correct}
          incorrect={!alreadyTypedWord.correct}
          faint
        >
          {alreadyTypedWord.word}
        </Input>
      ))}
    </>
  );
};

const CurrentLine = styled.div`
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: left;
`;

const ShowLine = ({
  lineNumber,
  prismLanguageToUse,
  codeToType,
}: {
  lineNumber: number;
  prismLanguageToUse: string;
  codeToType: string[];
}) => {
  const line = codeToType?.[lineNumber];
  if (!line) {
    return null;
  }
  const lineHTML = Prism.highlight(
    line,
    Prism.languages[prismLanguageToUse],
    prismLanguageToUse
  );

  return (
    <div>
      {line && (
        <CodeTextDiv
          style={{
            opacity: 0.3,
          }}
          dangerouslySetInnerHTML={{
            __html: lineHTML,
          }}
        />
      )}
    </div>
  );
};
const NextLines = ({
  currentLine,
  prismLanguageToUse,
  codeToType,
  linesToShow,
}: {
  currentLine: number;
  prismLanguageToUse: string;
  codeToType: string[];
  linesToShow: number;
}) => {
  return (
    <div>
      {_.range(linesToShow).map((i) => {
        return (
          <ShowLine
            codeToType={codeToType}
            key={i}
            lineNumber={currentLine + i + 1}
            prismLanguageToUse={prismLanguageToUse}
          ></ShowLine>
        );
      })}
    </div>
  );
};

const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-contents: center;
  flex-direction: row;

  ${breakpoints.down("small")`
  flex-direction: column;
  `}
`;

const Timer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const renderTime = ({ remainingTime }: { remainingTime: number }) => {
  return (
    <Timer>
      <div style={{ fontWeight: "bold", fontSize: 30, marginTop: -8 }}>
        {remainingTime}
      </div>
      <div
        style={{
          opacity: 0.3,
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: 16,
          marginTop: -2,
        }}
      >
        seconds
      </div>
    </Timer>
  );
};

const StatsSquare = ({
  num,
  desc,
  style = {},
}: {
  num: number | string;
  desc: string;
  style: any;
}) => {
  return (
    <div
      style={{
        height: 100,
        width: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "white",
        border: "1px solid #EAEAEA",
        borderRadius: 10,
        flexDirection: "column",
        ...style,
      }}
    >
      <div
        style={{
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: 30,
        }}
      >
        {num}
      </div>
      <div
        style={{
          opacity: 0.3,
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: 16,
        }}
      >
        {desc}
      </div>
    </div>
  );
};

const TimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-right: 90px;

  ${breakpoints.down("small")`
  margin-right: 0px;
  margin-bottom: 20px;
  `}
`;

const Stats = ({
  correctWords,
  correctChars,
  accuracy,
  hasStarted,
  onComplete,
  runNumber,
}: {
  correctChars: number;
  correctWords: number;
  accuracy: string;
  hasStarted: boolean;
  runNumber: number;
  onComplete: () => void;
}) => {
  return (
    <StatsContainer>
      <TimerContainer>
        <CountdownCircleTimer
          key={runNumber}
          isPlaying={hasStarted}
          duration={60}
          size={100}
          strokeWidth={4}
          colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]] as any}
          onComplete={() => {
            onComplete();
          }}
        >
          {renderTime}
        </CountdownCircleTimer>
      </TimerContainer>
      <div style={{ display: "flex" }}>
        <StatsSquare
          num={correctWords}
          desc="words/min"
          style={{ marginRight: 25 }}
        />
        <StatsSquare
          num={accuracy}
          desc="accuracy"
          style={{ marginRight: 25 }}
        />
        <StatsSquare num={correctChars} desc="chars/min" style={{}} />
      </div>
    </StatsContainer>
  );
};

const customSingleValue = ({ data }: any) => {
  const Icon = data.icon;
  return (
    <div className="input-select">
      <div className="input-select__single-value" style={{ display: "flex" }}>
        {Icon && (
          <span style={{ marginRight: 7 }} className="input-select__icon">
            <Icon
              style={{ height: 16, width: 16 }}
              alt={`${data.label} logo`}
            ></Icon>
          </span>
        )}
        <span>{data.label}</span>
      </div>
    </div>
  );
};
const SELECT_CURSOR_STYLE = {
  option: (styles: any) => ({
    ...styles,
    cursor: "pointer",
    fontFamily: "Inter",
  }),
  control: (styles: any) => ({
    ...styles,
    cursor: "pointer",
    width: "300px",
    fontFamily: "Inter",
  }),
  menu: (styles: any) => ({
    ...styles,
    width: "300px",
    fontFamily: "Inter",
  }),
};

const SubTitleContainer = styled.div`
  margin-top: 0px;
  display: flex;
  align-items: center;
`;
const SubTitle = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 10px;
  opacity: 0.6;
`;

const MainTitle = styled.h1`
  margin-top: 12px;
  margin-bottom: 60px;

  line-height: 20px;
  font-weight: bold;
  font-size: 50px;
  ${breakpoints.down("small")`
  font-size: 35px;
  `}
`;

const Subtext = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  opacity: 0.6;
  margin-bottom: 10px;
  margin-top: 10px;
`;

type LanguageType = {
  prismLanguageToUse: string;
  icon: any;
  value: string;
  label: string;
};

const ButtonLink = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
`;

const IndexPage = ({ location }) => {
  const [showQuestionsModal, setShowQuestionsModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    const urlParamLanguage = new URLSearchParams(location.search).get(
      "language"
    );
    if (
      urlParamLanguage &&
      LANGUAGES.find((l) => l.value === urlParamLanguage)
    ) {
      setLanguage(urlParamLanguage);
    }
  }, [location]);

  const [hasStarted, setHasStarted] = useState(false);

  const [language, setLanguage] = useState("javascript");
  const [languageUrlParam, setLanguageUrlParam] = useQueryParam(
    "language",
    StringParam
  );

  const languageSelected = LANGUAGES.find(
    (l) => l.value === language
  ) as LanguageType;

  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  const codeToType = getCodeForLanguage(language);

  const [currentLine, setCurrentLine] = useState(0);
  const goalLine = codeToType[currentLine];

  const currentInput = useRef<HTMLSpanElement>(null);
  const [alreadyTypedWords, setAlreadyTypedWords] = useState<AlreadyTypedWords>(
    []
  );

  const [runNumber, setRunNumber] = useState(0);

  const [correctWords, setCorrectWords] = useState(0);
  const [correctChars, setCorrectChars] = useState(0);
  const [incorrectWords, setIncorrectWords] = useState(0);

  const goToNextLine = () => {
    // Order important
    setCurrentWordIndex(0);
    setAlreadyTypedWords([]);
    setCurrentLine(currentLine + 1);
  };

  /**
   * You have whatever you have typed in so far.
   * This will either be totally correct (blue) or striked out
   *
   * The remaining string shown is whatever is not matched (from the back)
   */

  const wordsAttempted = correctWords + incorrectWords;
  const accuracy: string =
    (wordsAttempted == 0
      ? "100"
      : Math.round((correctWords / wordsAttempted) * 100)) + "%";

  const reset = () => {
    setHasStarted(false);
    setCurrentWordIndex(0);
    setCurrentLine(0);
    setCorrectChars(0);
    setCorrectWords(0);
    setIncorrectWords(0);
    setAlreadyTypedWords([]);
    setRunNumber(runNumber + 1);
  };

  const closeModal = () => {
    reset();

    setModalOpen(false);
  };

  if (!goalLine) {
    notifySlack(
      "someone ran out of input on language..." + languageSelected.label
    );
    return (
      <ResultsModal
        wordsPerMinute={correctWords}
        accuracy={accuracy}
        language={languageSelected.label}
        charsPerMinute={correctChars}
        close={() => {
          closeModal();
        }}
      />
    );
  }

  const wordsToType = goalLine.split(" ");

  const currentGoalWord = wordsToType[currentWordIndex] || "";
  const remainingWords = wordsToType.slice(
    currentWordIndex + 1,
    wordsToType.length
  );

  const prismLanguageToUse = languageSelected.prismLanguageToUse;
  const remainingWordsHtml = Prism.highlight(
    remainingWords.join(" "),
    Prism.languages[prismLanguageToUse],
    prismLanguageToUse
  );

  const Icon = languageSelected.icon;

  const TITLE = "Coding Speed Test";
  const DESCRIPTION =
    "Test your coding typing speed in Javascript, Python, C, Java, Go and more. Find out your programming words per minute (WPM) for free, online.";
  const IMAGE_URL =
    "https://github.com/dominicwhyte/codingspeedtestimage/blob/main/metaImageCodingSpeedTest.png?raw=true";
  const URL = "https://codingspeedtest.com";
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="en"></html>
        <title>{TITLE}</title>
        <link rel="canonical" href={URL} />
        <meta name="description" content={DESCRIPTION} />
        <meta property="og:title" content={TITLE} />
        <meta property="og:description" content={DESCRIPTION} />
        <meta property="og:url" content={URL} />
        <meta property="og:site_name" content={TITLE} />
        <meta name="twitter:image:alt" content={TITLE} />

        <meta property="og:image" content={IMAGE_URL} />
        <meta name="twitter:card" content="summary" />
      </Helmet>

      <MainContent>
        <FilloutBranding />

        <SubTitleContainer>
          <Icon
            style={{ height: 20, width: 20, marginRight: 5 }}
            alt={`${language} logo`}
          />
          <SubTitle>{languageSelected.label}</SubTitle>
        </SubTitleContainer>

        <MainTitle>Coding Speed Test </MainTitle>
        <Stats
          runNumber={runNumber}
          correctWords={correctWords}
          correctChars={correctChars}
          accuracy={accuracy}
          hasStarted={hasStarted}
          onComplete={() => {
            setModalOpen(true);
          }}
        ></Stats>

        <MainInputContainer
          onClick={() => {
            currentInput?.current?.focus();
          }}
        >
          {!hasStarted && <AttentionGrabber />}
          <ShowLine
            codeToType={codeToType}
            lineNumber={currentLine - 1}
            prismLanguageToUse={languageSelected.prismLanguageToUse}
          ></ShowLine>
          <CurrentLine>
            <AlreadyTypedWords
              alreadyTypedWords={alreadyTypedWords}
            ></AlreadyTypedWords>
            <AttemptInput
              currentWordIndex={currentWordIndex}
              isLastWordInLine={remainingWords.length === 0}
              currentLine={currentLine}
              goalWord={currentGoalWord}
              setHasStarted={setHasStarted}
              hasStarted={hasStarted}
              onFinish={(correct, attempt) => {
                if (correct) {
                  setCorrectWords(correctWords + 1);
                  setCorrectChars(correctChars + currentGoalWord.length);
                } else {
                  setIncorrectWords(incorrectWords + 1);
                }
                setAlreadyTypedWords(
                  alreadyTypedWords.concat({ word: currentGoalWord, correct })
                );

                if (remainingWords.length === 0) {
                  goToNextLine();
                } else {
                  setCurrentWordIndex(currentWordIndex + 1);
                }
              }}
              currentInput={currentInput}
            />
            {/* Remaining words to be typed */}
            <CodeTextDiv
              dangerouslySetInnerHTML={{ __html: remainingWordsHtml }}
            />
          </CurrentLine>
          <NextLines
            // show extra line when starting out since we don't show a prior line
            linesToShow={currentLine === 0 ? 3 : 2}
            codeToType={codeToType}
            currentLine={currentLine}
            prismLanguageToUse={languageSelected.prismLanguageToUse}
          ></NextLines>
        </MainInputContainer>
        <Subtext>Try another language?</Subtext>

        <Select
          instanceId="language-dropdown"
          isSearchable
          value={languageSelected}
          onChange={(v: any) => {
            reset();
            setLanguage(v.value);
            setLanguageUrlParam(v.value);
          }}
          options={LANGUAGES}
          styles={SELECT_CURSOR_STYLE}
          components={{ SingleValue: customSingleValue }}
        />
        <ButtonLink
          style={{ marginTop: 15 }}
          onClick={() => {
            setShowQuestionsModal(true);
          }}
        >
          Questions/feedback
        </ButtonLink>
        <ReactModal
          isOpen={showQuestionsModal}
          contentLabel="Questions/feedback"
          style={{
            // overlay: {
            //   backgroundColor: "papayawhip",
            // },
            content: {
              maxWidth: 300,
              width: "80%",

              height: 250,
              top: "50%",
              left: "50%",
              "-ms-transform": "translate(-50%,-50%)",
              transform: "translate(-50%,-50%)",
            },
          }}
          onRequestClose={() => setShowQuestionsModal(false)}
        >
          <QuestionsModal setShowQuestionsModal={setShowQuestionsModal} />
        </ReactModal>

        <FAQs />
      </MainContent>
      <ReactModal
        isOpen={modalOpen}
        contentLabel="Results"
        style={{
          // overlay: {
          //   backgroundColor: "papayawhip",
          // },
          content: {
            maxWidth: 600,
            width: "80%",

            height: 370,
            top: "50%",
            left: "50%",
            "-ms-transform": "translate(-50%,-50%)",
            transform: "translate(-50%,-50%)",
          },
        }}
        onRequestClose={() => closeModal()}
      >
        <ResultsModal
          wordsPerMinute={correctWords}
          accuracy={accuracy}
          language={languageSelected.label}
          charsPerMinute={correctChars}
          close={() => {
            closeModal();
          }}
        />
      </ReactModal>
    </Layout>
  );
};

export default IndexPage;
