import React, { useState, useEffect, useRef } from "react";
import JavascriptLogo from "./images/logos/javascript.svg";
import PythonLogo from "./images/logos/python.svg";
import CLogo from "./images/logos/c.svg";
import GoLogo from "./images/logos/go.svg";
import ComputerLogo from "./images/logos/asm6502.svg";
import JavaLogo from "./images/logos/java.svg";
import { Link } from "gatsby";
import { last } from "lodash";

/**
 * Steps to add a language
 * 1. Add file in rawCode. Use https://tools.knowledgewalls.com/remove-comments-from-any-program-online to remove comments
 * 2. Add logo in images/logos and below
 * 4. Add language to .babelrc
 *
 */

export const LANGUAGES = [
  {
    value: "javascript",
    prismLanguageToUse: "javascript",
    label: "JavaScript",

    icon: JavascriptLogo,
  },
  {
    value: "python",
    prismLanguageToUse: "python",
    label: "Python",
    icon: PythonLogo,
  },
  {
    value: "c",
    prismLanguageToUse: "c",
    label: "C",
    icon: CLogo,
  },
  {
    value: "go",
    prismLanguageToUse: "go",
    label: "Go",
    icon: GoLogo,
  },
  {
    value: "asm6502",
    prismLanguageToUse: "asm6502",
    label: "Assembly (16-bit x86)",
    icon: ComputerLogo,
  },
  {
    value: "machine",
    prismLanguageToUse: "asm6502",
    label: "Machine Code",
    icon: ComputerLogo,
  },
  {
    value: "java",
    prismLanguageToUse: "java",
    label: "Java",
    icon: JavaLogo,
  },
];

export const FAQS = [
  {
    question: "Which programming languages do you support? ",
    answer: (
      <>
        You can test your coding typing speed in{" "}
        {LANGUAGES.slice(0, LANGUAGES.length - 1).map((l) => (
          <>
            <Link key={l.value} to={`/?language=${l.value}`}>
              {l.label}
            </Link>
            ,{" "}
          </>
        ))}
        and{" "}
        <Link
          key={last(LANGUAGES)!.value}
          to={`/?language=${last(LANGUAGES)!.value}`}
        >
          {last(LANGUAGES)!.label}
        </Link>
      </>
    ),
  },
  {
    question: "Inspiration",
    answer: (
      <>
        Coding Speed Test was inspired by{" "}
        <a
          href="https://www.livechat.com/typing-speed-test/#/"
          target="_blank"
          rel="noreferrer"
        >
          this typing speed test
        </a>
        . Check it out to test your regular typing skills!
      </>
    ),
  },

  {
    question: "Is this programming typing test free to use?",
    answer:
      "Yes, the coding speed test is completely free. If you enjoyed using it, please share it with your friends :)",
  },
  {
    question: "What are WPM and CPM?",
    answer:
      "WPM stands for words per minute and CPM stands for characters per minute. They are both ways of measuring your typing speed.",
  },
  {
    question:
      "What is the best way to practice and improve your typing speed as a programmer?",
    answer:
      'The best way to improve your programming typing speed is to practice typing code. Code uses a higher frequency of special characters than natural language, so normal typing practice tests aren\'t as effective. You can also try improving your typing setup (e.g. better keyboard + desk) and not looking at your keyboard when typing ("touch typing").',
  },
  {
    question: "How is typing code different from typing natural language?",
    answer: `Programming languages use a much higher frequency of special characters, like the semicolon ";", curly brackets "{}", parenthesis "()", equal sign ("=") and more. This makes the coding speed test harder than normal typing speed tests (unless you are a very experienced programmer!).`,
  },
];

export const COLORS = {
  red: "#ee0007",
  blue: "rgba(12, 73, 227, 1)",
  accent: "#ff4154",
};

export const MAIN_URL = "https://codingspeedtest.com";
export const SPONSOR_SHARE_URL =
  "https://fillout.com?utm_source=sponsor&utm_campaign=coding_speed_test";
