import React from "react";
import styled from "styled-components";
import { COLORS, MAIN_URL, SPONSOR_SHARE_URL } from "../constants";

const Button = styled.button`
  background: ${COLORS.accent};
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
`;
export default Button;
