import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterIcon,
  FacebookIcon,
  TwitterShareButton,
} from "react-share";
import { notifySlack } from "../utils";
import Button from "./Button";
import CloseButton from "./CloseButton";
import { COLORS, MAIN_URL, SPONSOR_SHARE_URL } from "../constants";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import pluralize from "pluralize";
import { useEffect } from "react";
import breakpoints from "./breakpoints";

const messageForScore = (score: number): { title: string; desc: string } => {
  if (score > 60) {
    return {
      title: "10x engineer",
      desc: "Turns out they do exist, after all.",
    };
  }
  if (score > 50) {
    return {
      title: "Distinguished engineer",
      desc: "Jeff Dean would be proud.",
    };
  }
  if (score > 40) {
    return {
      title: "Staff engineer",
      desc: "That was fast.",
    };
  }
  if (score > 30) {
    return {
      title: "Senior engineer",
      desc: "Not bad but.. where would you be without Stack Overflow?",
    };
  }
  if (score > 20) {
    return {
      title: "Junior engineer",
      desc: "You type fast... just don't break prod.",
    };
  }
  if (score > 10) {
    return {
      title: "Software Intern",
      desc: "Please invert a binary tree instead...",
    };
  }
  return {
    title: "Hello, world",
    desc: "Back to the programming whiteboard...",
  };
};
const Title = styled.div`
  font-weight: bold;
  font-size: 40px;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 25px;
`;

const Highlight = styled.span`
  background: ${COLORS.accent};
  padding: 2px 5px;
  border-radius: 4px;
  color: white;
  display: inline-block;
`;

const PaddingContainer = styled.div`
  padding: 20px;

  ${breakpoints.down("small")`
  padding: 0px;
  `}
`;

const SocialContainer = styled.div`
  margin-top: 45px;

  ${breakpoints.down("small")`

  margin-top: 20px;
  `}
`;

const ResultsModal = ({
  wordsPerMinute,
  charsPerMinute,
  accuracy,
  language,
  close,
}: {
  wordsPerMinute: number;
  accuracy: string;
  language: string;
  charsPerMinute: number;
  close: () => void;
}) => {
  useEffect(() => {
    const message =
      "Submission completed: " +
      [language, wordsPerMinute, charsPerMinute, accuracy].join(", ");
    notifySlack(message);
  }, []);
  const message = messageForScore(wordsPerMinute);
  const quote = `${message.title} status unlocked. I type ${language} at ${wordsPerMinute} words/minute with ${accuracy} accuracy. What's your coding speed?`;
  return (
    <div>
      <CloseButton close={close}></CloseButton>
      <PaddingContainer
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: 20 }}>{message.title}</Title>
        <Description>
          {message.desc} You type {language} at{" "}
          <Highlight>
            {wordsPerMinute} {pluralize("word", wordsPerMinute)}/minute
          </Highlight>{" "}
          (<strong>{charsPerMinute}</strong> {pluralize("char", charsPerMinute)}
          /minute) with <strong>{accuracy}</strong> accuracy.
        </Description>
        <SocialContainer style={{ display: "flex", alignItems: "center" }}>
          <FacebookShareButton
            url={MAIN_URL}
            style={{ marginRight: 3 }}
            quote={quote}
            className="Demo__some-network__share-button"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton
            url={MAIN_URL}
            title={quote}
            style={{ marginRight: 3 }}
            className="Demo__some-network__share-button"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <LinkedinShareButton
            url={MAIN_URL}
            className="Demo__some-network__share-button"
            style={{ marginRight: 20 }}
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <Button onClick={() => close()}>Try again</Button>
        </SocialContainer>
        <div
          style={{
            fontSize: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",

            marginTop: 35,
          }}
        >
          <div style={{ opacity: 0.6 }}>
            What’s faster than {wordsPerMinute}{" "}
            {pluralize("word", wordsPerMinute)}/min?<br></br> Building a form
            with{" "}
            <a target="_blank" href={SPONSOR_SHARE_URL} rel="noreferrer">
              fillout.com
            </a>
          </div>
          <a
            target="_blank"
            href={SPONSOR_SHARE_URL}
            rel="noreferrer"
            style={{
              marginLeft: 20,
              fontWeight: 900,
              fontSize: 24,
              color: "black",
              textDecoration: "none",
            }}
          >
            fillout.com
          </a>
        </div>
      </PaddingContainer>
    </div>
  );
};
export default ResultsModal;
