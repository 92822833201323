import React from "react";

const CloseButton = ({ close }: { close: () => void }) => {
  return (
    <div
      style={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
      }}
      onClick={() => close()}
    >
      {" "}
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 6L6 18"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6L18 18"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default CloseButton;
