import * as React from "react";
import "./layout.css";

import styled from "styled-components";

const LayoutContainer = styled.div`
  a {
    color: rgba(0, 71, 119, 1);
  }
`;

const Layout = ({ children }: any) => {
  return <LayoutContainer>{children}</LayoutContainer>;
};

export default Layout;
