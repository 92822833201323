import React from "react";
import "./AttentionGrabber.css";
import styled from "styled-components";
import breakpoints from "./breakpoints";

const Container = styled.span`
  ${breakpoints.down("small")`
  display: none;
  `}
  margin-left: -60px;

  ${breakpoints.down("large")`
  margin-left: -30px;
  `}
`;

const AttentionGrabber = ({ }) => {
  return (
    <Container
      style={{
        position: "absolute",
        color: "white",
        padding: 5,
        marginTop: -43,
      }}
      className="attention-grabber tooltip"
    >
      Type to begin
    </Container>
  );
};
export default AttentionGrabber;
