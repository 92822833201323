import styledBreakpoint from "@humblebee/styled-components-breakpoint";

const smallBreakpoint = 544;
const mediumBreakpoint = 768; // devices larger than the iPhone 11 Pro (812px in landscape mode)
const largeBreakpoint = 1012; // devices larger than the regular iPad (1024px in landscape mode)
const xLargeBreakpoint = 1280; //

const breakpoint = styledBreakpoint({
  small: smallBreakpoint,
  medium: mediumBreakpoint,
  large: largeBreakpoint,
  xlarge: xLargeBreakpoint,
});

export { breakpoint as default, mediumBreakpoint, largeBreakpoint };
