const code = [
  "package example;",
  "import java.io.IOException;",
  "public class CsvReaderExample {",
  "public static void main(final String[] args) {",
  "simple();",
  "forEachLambda();",
  "}",
  "private static void simple() {",
  'System.out.print("For-Each loop: ");',
  "System.out.println(csvRow.getFields());",
  "}",
  "}",
  "private static void forEachLambda() {",
  'System.out.print("Loop using forEach lambda: ");',
  'CsvReader.builder().build("foo,bar")',
  ".forEach(System.out::println);",
  "}",
  "private static void stream() {",
  'System.out.printf("CSV contains %d rows%n",',
  'CsvReader.builder().build("foo,bar").stream().count());',
  "}",
  "private static void iterator() {",
  'System.out.print("Iterator loop: ");',
  "for (final Iterator<CsvRow> iterator = CsvReader.builder()",
  "final CsvRow csvRow = iterator.next();",
  "System.out.print(csvRow.getFields());",
  "if (iterator.hasNext()) {",
  'System.out.print(" || ");',
  "} else {",
  "System.out.println();",
  "}",
  "}",
  "}",
  "private static void header() {",
  "final Optional<NamedCsvRow> first = NamedCsvReader.builder()",
  '.build("header1,header2\\nvalue1,value2")',
  ".stream().findFirst();",
  "}",
  "private static void advancedConfiguration() {",
  "final String parsedData = CsvReader.builder()",
  ".fieldSeparator(';')",
  ".quoteCharacter('\\'')",
  ".commentStrategy(CommentStrategy.SKIP)",
  ".commentCharacter('#')",
  ".skipEmptyRows(true)",
  ".errorOnDifferentFieldCount(false)",
  ".build(data)",
  ".stream()",
  ".map(csvRow -> csvRow.getFields().toString())",
  '.collect(Collectors.joining(" || "));',
  "}",
  "private static void file() {",
  "try {",
  'final Path path = Files.createTempFile("fastcsv", ".csv");',
  'Files.write(path, "foo,bar\\n".getBytes(UTF_8));',
  "csvReader.forEach(System.out::println);",
  "}",
  "} catch (final IOException e) {",
  "throw new UncheckedIOException(e);",
  "}",
  "}",
  "}",
];

export default code;
